<template>
  <div class="h-[32rem]">
    <div>
      <div class="mt-5 flex justify-center">
        <RadioGroup
          v-model="competitor"
          class="md:text-md grid grid-cols-3 gap-x-1 rounded-full text-center text-sm leading-5 ring-1 ring-inset ring-gray-200 md:font-semibold">
          <RadioGroupOption v-slot="{ checked }" :value="0" as="template">
            <div
              :class="[
                checked ? 'bg-indigo-600 text-white' : 'text-gray-800',
                'cursor-pointer rounded-full px-2.5 py-1',
              ]"
              class="px-1">
              <span>TeachMeHIPAA</span>
            </div>
          </RadioGroupOption>
          <RadioGroupOption v-slot="{ checked }" :value="1">
            <div
              :class="[
                checked ? 'bg-indigo-600 text-white' : 'text-gray-800',
                'cursor-pointer rounded-full px-2.5 py-1',
              ]">
              <span>HIPAA Training</span>
            </div>
          </RadioGroupOption>
          <RadioGroupOption v-slot="{ checked }" :value="2">
            <div
              :class="[
                checked ? 'bg-indigo-600 text-white' : 'text-gray-800',
                'cursor-pointer rounded-full px-2.5 py-1',
              ]">
              <span>Accountable</span>
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </div>
    </div>
    <div class="mt-5 p-2">
      <div>
        <ul>
          <li
            v-for="(feature, index) in vsCompetitors[parseInt(competitor)].features"
            :key="index"
            class="mt-2 flex gap-x-3 pl-2 font-semibold text-gray-600">
            <check-icon class="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
            {{ feature }}
          </li>
        </ul>
        <div>
          <div class="mt-4 flex justify-end">
            <p class="items-baseline gap-x-1">
              <span class="text-xl font-bold tracking-tight text-gray-900"
                >Total
                <span class="text-primary">{{
                  vsCompetitors[parseInt(competitor)].price
                }}</span></span
              >
              <span class="text-sm font-semibold leading-6 text-gray-600"
                >/{{ vsCompetitors[parseInt(competitor)].per }}</span
              >
              <span
                v-if="vsCompetitors[parseInt(competitor)].add"
                class="block text-right text-sm font-semibold text-gray-600"
                >+$1,000</span
              >
            </p>
          </div>
        </div>
        <div v-if="!parseInt(competitor)">
          <l-button
            variant="secondary"
            size="xl"
            class="mb-2 mt-10 w-full"
            @click="navigateTo('/signup')">
            Sign up
          </l-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { RadioGroup, RadioGroupOption } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const vsCompetitors = [
  {
    name: "TeachMeHIPAA",
    id: "teachmehipaa",
    price: "$17.95",
    per: "seat",
    features: [
      "HIPAA certification",
      "Free quiz retakes",
      "Employee participation tracking",
      "Unlimited premium support",
      "Annual certification reminders",
      "Free internal policies ($1,000 value)",
      "Free contract templates",
    ],
  },
  {
    name: "HIPAATraining",
    id: "hipaatraining",
    price: "$29.99",
    per: "seat",
    add: "+$1,000",
    features: [
      "Internal policies ($1,000 cost)",
      "Requires password sharing across organization",
      "Training content 10+ years old",
      "Reporting in complex spreadsheets",
    ],
  },
  {
    name: "Accountable",
    id: "accountable",
    price: "$4,000",
    per: "year",
    features: [
      "Automatic account creation",
      "Shallow check-the-box training solution",
      "Easy reporting and participation tracking",
      "Free contract and vendor tracking",
    ],
  },
];

const competitor = ref(0);
</script>
